import React, { useState } from 'react'
import { Typography, CircularProgress, Modal, Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ChartComponent from '../components/ChartComponent'
import MetricCardComponent from '../components/MetricCardComponent'
import CustomToggleButtonGroup from '../components/CustomToggleButtonGroup'
import { chartOptions, timeFrameOptions } from '../utils/metricsUtils'
import useFetchMetrics from '../hooks/useFetchmetrics'
import CustomDatePicker from '../components/CustomDatePicker'
import AlertComponent from '../components/AlertComponent'

const Ingestion = () => {
    const navigate = useNavigate()
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('D3')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false) // Add state to disable selectedTimeFrame button
    const [dateTimeRange, setDateTimeRange] = useState({})
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const metricsConfig = {
        NumberOfItemsReceived: {
            label: '# of Items Received (count)',
            title: '# Of Items Received',
            color: 'rgba(255, 99, 132)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        NumberOfItemsIngested: {
            label: '# of  Items Ingested (count)',
            title: '# Of Items Ingested',
            color: 'rgba(255, 99, 132)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        IngestionLatency: {
            label: 'Ingestion Latency (minutes)',
            title: 'Ingestion Latency',
            color: 'rgba(54, 162, 235)',
            type: 'line',
            aggregation: 'Average',
            threshold: 3 //3 min
        },

        ClientApi500Error: {
            label: 'Client API 500 Error (count)',
            title: 'Client API 500 Error',
            color: 'rgba(255, 159, 64)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        ClientApi400Error: {
            label: 'Client API 400 Error (count)',
            title: 'Client API 400 Error',
            color: 'rgba(153, 102, 255)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        }
    }
    //Fetch metrics data for ingestion dashboard and auto-refresh every 5 minutes
    const { chartsData, loading, metricsData, metricDescriptions } = useFetchMetrics(
        '/dashboard/ingestion',
        selectedTimeFrame,
        metricsConfig,
        300000,
        navigate,
        dateTimeRange
    )

    const handleTimeFrameChange = (e, newTimeFrame) => {
        if (newTimeFrame && newTimeFrame !== selectedTimeFrame) {
            setIsDisabled(true) // Disable the toggle button group
            setSelectedTimeFrame(newTimeFrame)
            setDateTimeRange({})
            setTimeout(() => setIsDisabled(false), 1000) // Re-enable after 1 second
        }
    }
    const handleCloseModal = () => setIsModalOpen(false)
    const handleCustomClick = () => {
        setIsDialogOpen(true) // Open the dialog
    }

    const handleDialogClose = (e) => {
        setIsDialogOpen(false) // Close the dialog
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setAlertOpen(false) // Close the Snackbar
    }

    const handleDateTimeChange = ({ startTime, endTime }) => {
        const currentTime = new Date()
        if (startTime < endTime && endTime < currentTime) {
            setDateTimeRange({
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString()
            })

            setIsDisabled(true) // Disable the toggle button group
            setTimeout(() => setIsDisabled(false), 1000) // Re-enable after 1 second
        } else {
            setAlertMessage(
                'Invalid date range: Ensure start time is less than end time and end time is less than the current time.'
            )
            setAlertOpen(true)
        }
    }
    return (
        <div
            style={{
                width: '110vw',
                backgroundColor: '#f0f0f5',
                padding: '1rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
        >
            <Typography
                variant="h5"
                style={{ fontWeight: 'bold', marginTop: '4rem', marginBottom: '0rem', color: 'black' }}
            >
                Ingestion Dashboard
            </Typography>
            <AlertComponent open={alertOpen} message={alertMessage} severity="error" onClose={handleAlertClose} />
            <div style={{ display: 'flex', paddingTop: '1rem', paddingBottom: '2rem' }}>
                <CustomToggleButtonGroup
                    value={selectedTimeFrame}
                    options={timeFrameOptions}
                    onChange={handleTimeFrameChange}
                    disabled={isDisabled}
                    groupStyle={{ backgroundColor: 'white' }}
                    onCustomClick={handleCustomClick}
                />
                <div style={{ flexShrink: 0, marginRight: '1rem' }}>
                    <CustomDatePicker open={isDialogOpen} onClose={handleDialogClose} onApply={handleDateTimeChange} />
                </div>
            </div>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: '1rem', color: 'black' }}>
                        Loading...
                    </Typography>
                </div>
            ) : (
                <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{
                            width: '100%',
                            paddingLeft: '10px',
                            display: 'grid',
                            gap: '0.5rem',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            marginBottom: '1.5rem'
                        }}
                    >
                        <MetricCardComponent
                            title="Total # of Items Received"
                            value={metricsData?.TotalNumberOfItemsReceived}
                            description={metricDescriptions?.TotalNumberOfItemsReceived}
                        />
                        <MetricCardComponent
                            title="Total # of Items Ingested"
                            value={metricsData?.TotalNumberOfItemsIngested}
                            description={metricDescriptions?.TotalNumberOfItemsIngested}
                        />
                        <MetricCardComponent
                            title="Total # of Items Failed"
                            value={`${Math.round(metricsData?.NumberOfItemsFailed)}%`}
                            description={metricDescriptions?.NumberOfItemsFailed}
                        />
                        <MetricCardComponent
                            title="Total # of Items Inflight"
                            value={`${Math.round(metricsData?.IngestionItemsInFlight)}%`}
                            description={metricDescriptions?.IngestionItemsInFlight}
                        />
                    </div>

                    <ChartComponent metricsData={chartsData} options={chartOptions(chartsData)} />
                </div>
            )}

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    style={{
                        backgroundColor: 'white',
                        padding: '2rem',
                        borderRadius: '8px',
                        width: '80%',
                        margin: 'auto',
                        marginTop: '10%'
                    }}
                >
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '1rem', color: 'black' }}>
                        No Data Found
                    </Typography>
                    <Button onClick={handleCloseModal} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}

export default Ingestion
