import React, { useState } from 'react'
import { Card, CardContent, Typography, Tooltip, IconButton, Box } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

// Define a reusable Ingestion Metric Card component
const MetricCardComponent = ({ title, value, description }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <Card
            sx={{
                position: 'relative',
                width: '20rem',
                height: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 16px 28px -1px rgba(5, 28, 44, 0.40)',
                padding: '1rem'
            }}
        >
            {/* Card content container */}
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}
            >
                {/* Title and InfoIcon aligned */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '0.5rem',
                        position: 'relative',
                        marginBottom: '1rem'
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: '#979494',
                        }}
                    >
                        {title}
                    </Typography>

                    <Tooltip
                        title={description || 'No description available'}
                        open={showTooltip}
                        onClose={() => setShowTooltip(false)}
                        onOpen={() => setShowTooltip(true)}
                        arrow
                    >
                        <IconButton
                            size="small"
                            onClick={() => setShowTooltip(true)}
                            sx={{
                                color: 'grey',
                                padding: '4px',
                            }}
                        >
                            <InfoIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* Value display */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '38px',
                        fontFamily: 'monospace',
                        color: 'black',
                        textAlign: 'center',
                    }}
                >
                    {value || '0'}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default MetricCardComponent
