import React, { useState } from 'react'
import { Typography, CircularProgress } from '@mui/material'
// import { MenuItem, Select, FormControl, InputLabel } from '@mui/material'//For Future implementation
import ChartComponent from '../components/ChartComponent'
import CustomToggleButtonGroup from '../components/CustomToggleButtonGroup'
import useFetchMetrics from '../hooks/useFetchmetrics'
import { timeFrameOptions, chartOptions } from '../utils/metricsUtils'
import CustomDatePicker from '../components/CustomDatePicker'
import AlertComponent from '../components/AlertComponent'

const RelatedContent = () => {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('D3')
    const [isDisabled, setIsDisabled] = useState(false)
    const [dateTimeRange, setDateTimeRange] = useState({})
    const [isDialogOpen, setIsDialogOpen] = useState(false) // State for dialog visibility
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    // const [selectedPeriod, setSelectedPeriod] = useState('5m') // //For Future implementation: Default period
    // For Future implementation: Period to seconds mapping
    // const period_to_seconds = {
    //     '5 minutes': 300, // 5 minutes = 300 seconds
    //     '15 minutes': 900, // 15 minutes = 900 seconds
    //     '1 hour': 3600, // 1 hour = 3600 seconds
    //     '6 hours': 21600, // 6 hours = 21,600 seconds
    //     '1 day': 86400 // 1 day = 86,400 seconds
    // }
    const metricsConfig = {
        LATENCY: {
            label: 'Latency (ms) ',
            title: 'Latency (ms)',
            color: 'rgba(54, 162, 235)',
            type: 'bar',
            aggregation: 'Average',
            threshold: 500
        },
        REQUEST_COUNT: {
            label: 'Request Count (count)',
            title: 'Total # of Requests (Count)',
            color: 'rgba(255, 99, 132)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        ERROR_RATE_5XX: {
            label: 'Error Rate 5XX (count) ',
            title: 'Error Rate 5XX (Count)',
            color: 'rgba(255, 159, 64)',
            type: 'bar',
            aggregation: 'Sum'
        },
        ERROR_RATE_4XX: {
            label: 'Error Rate 4XX (count) ',
            title: 'Error Rate 4XX (Count)',
            color: 'rgba(153, 102, 255)',
            type: 'bar',
            aggregation: 'Sum'
        },
        RESPONSE_SIZE: {
            label: 'Response Size (count) ',
            title: 'Response Size (Count)',
            color: 'rgba(75, 192, 192)',
            type: 'bar',
            aggregation: 'Sum'
        }
    }

    const { chartsData, loading } = useFetchMetrics(
        '/dashboard/related-contents',
        selectedTimeFrame,
        metricsConfig,
        300000, // Auto-refresh every 5 min
        'null',
        dateTimeRange
        // period_to_seconds[selectedPeriod] //// For Future implementation: Use selected period
    )
    const handleTimeFrameChange = (e, newTimeFrame) => {
        if (newTimeFrame && newTimeFrame !== selectedTimeFrame) {
            setIsDisabled(true)
            setSelectedTimeFrame(newTimeFrame)
            setDateTimeRange({})
            setTimeout(() => setIsDisabled(false), 1000)
        }
    }
    const handleCustomClick = () => {
        setIsDialogOpen(true) // Open the dialog
    }

    const handleDialogClose = (e) => {
        setIsDialogOpen(false) // Close the dialog
    }
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setAlertOpen(false) // Close the Snackbar
    }

    const handleDateTimeChange = ({ startTime, endTime }) => {
        const currentTime = new Date()
        if (startTime < endTime && endTime < currentTime) {
            setDateTimeRange({
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString()
            })
        } else {
            setAlertMessage(
                'Invalid date range: Ensure start time is less than end time and end time is less than the current time.'
            )
            setAlertOpen(true)
        }
    }
    // For Future implementation:
    // const handlePeriodChange = (event) => {
    //     setSelectedPeriod(event.target.value)
    // }
    return (
        <div
            style={{
                width: '100vw',
                backgroundColor: '#f0f0f5',
                padding: '1rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
        >
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    marginTop: '4rem',
                    marginBottom: '1rem',
                    color: 'black'
                }}
            >
                Related Content Dashboard
            </Typography>

            <AlertComponent open={alertOpen} message={alertMessage} severity="error" onClose={handleAlertClose} />
            {/*  for CustomToggleButtonGroup and DateTimeRangePicker */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '1rem'
                }}
            >
                {/* Left: Toggle Button Group */}
                <CustomToggleButtonGroup
                    value={selectedTimeFrame}
                    options={timeFrameOptions}
                    onChange={handleTimeFrameChange}
                    disabled={isDisabled}
                    groupStyle={{ backgroundColor: 'white' }}
                    onCustomClick={handleCustomClick}
                />
                <div style={{ flexShrink: 0, marginRight: '1rem' }}>
                    <CustomDatePicker open={isDialogOpen} onClose={handleDialogClose} onApply={handleDateTimeChange} />
                </div>
                {/* // For Future implementation: Right: Period Dropdown */}
                {/* <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
                    <InputLabel id="period-select-label" color="primary">
                        Period
                    </InputLabel>
                    <Select
                        labelId="period-select-label"
                        id="period-select"
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                        label="Period"
                    >
                        {Object.keys(period_to_seconds).map((key) => (
                            <MenuItem key={key} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}
            </div>

            {/* Loading and Chart Section */}
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: '1rem', color: 'black' }}>
                        Loading...
                    </Typography>
                </div>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '0.5rem'
                    }}
                >
                    <ChartComponent metricsData={chartsData} options={chartOptions(chartsData)} />
                </div>
            )}
        </div>
    )
}

export default RelatedContent
