import React, { useState } from 'react'
import { Typography, CircularProgress } from '@mui/material'
import ChartComponent from '../components/ChartComponent'
import CustomToggleButtonGroup from '../components/CustomToggleButtonGroup'
import { chartOptions, timeFrameOptions } from '../utils/metricsUtils'
import useFetchMetrics from '../hooks/useFetchmetrics'
import { Chart, registerables } from 'chart.js'
import CustomDatePicker from '../components/CustomDatePicker'
import AlertComponent from '../components/AlertComponent'


Chart.register(...registerables)

const Storylines = () => {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('D3')
    const [isDisabled, setIsDisabled] = useState(false) // Add state to disable selectedTimeFrame button
    const [dateTimeRange, setDateTimeRange] = useState({})
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const metricsConfig = {
        LATENCY: {
            label: 'Latency (ms)',
            title: 'Latency (ms)',
            color: 'rgba(54, 162, 235)',
            type: 'bar',
            aggregation: 'Average',
            threshold: 500
        },
        REQUEST_COUNT: {
            label: 'Request Count (count)',
            title: 'Total # of Requests (Count)',
            color: 'rgba(255, 99, 132)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        ERROR_RATE_5XX: {
            label: 'Error Rate 5XX (count)',
            title: 'Error Rate 5XX (Count)',
            color: 'rgba(255, 159, 64)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        ERROR_RATE_4XX: {
            label: 'Error Rate 4XX (count)',
            title: 'Error Rate 4XX (Count)',
            color: 'rgba(153, 102, 255)',
            type: 'bar',
            aggregation: 'Sum',
            threshold: null
        },
        RESPONSE_SIZE:
         {
            label: 'Response Size (count)',
            title: 'Response Size (Count)',
            color: 'rgba(75, 192, 184, 0.9)',
            type: 'bar',
            aggregation: 'Sum'}
    }
    const { chartsData, loading } = useFetchMetrics(
        '/dashboard/story-line',
        selectedTimeFrame,
        metricsConfig,
        300000,
        'null',
        dateTimeRange
    )
    const handleTimeFrameChange = (e, newTimeFrame) => {
        if (newTimeFrame && newTimeFrame !== selectedTimeFrame) {
            setIsDisabled(true) // Disable the toggle button group
            setSelectedTimeFrame(newTimeFrame)
            setDateTimeRange({}); 
            setTimeout(() => setIsDisabled(false), 1000) // Re-enable after 1 second
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setAlertOpen(false) // Close the Snackbar
    }

    const handleDateTimeChange = ({ startTime, endTime }) => {
        const currentTime = new Date()
        if (startTime < endTime && endTime < currentTime) {
            setDateTimeRange({
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString()
            })
        } else {
            setAlertMessage(
                'Invalid date range: Ensure start time is less than end time and end time is less than the current time.'
            )
            setAlertOpen(true)
        }
    }
    const handleCustomClick = () => {
        setIsDialogOpen(true) // Open the dialog
    }

    const handleDialogClose = (e) => {
        setIsDialogOpen(false) // Close the dialog
    }
    return (
        <div
            style={{
                width: '100vw',
                backgroundColor: '#f0f0f5',
                padding: '1rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
        >
            <Typography
                variant="h5"
                style={{ fontWeight: 'bold', marginTop: '4rem', marginBottom: '1rem', color: 'black' }}
            >
                Storylines Dashboard
            </Typography>
            <AlertComponent open={alertOpen} message={alertMessage} severity="error" onClose={handleAlertClose} />
            <div style={{ display: 'flex', paddingTop: '1rem', paddingBottom: '2rem' }}>
                <CustomToggleButtonGroup
                    value={selectedTimeFrame}
                    options={timeFrameOptions}
                    onChange={handleTimeFrameChange}
                    disabled={isDisabled}
                    groupStyle={{ backgroundColor: 'white' }}
                    onCustomClick={handleCustomClick}
                />
                <div style={{ flexShrink: 0, marginRight: '1rem' }}>
                    <CustomDatePicker open={isDialogOpen} onClose={handleDialogClose} onApply={handleDateTimeChange} />
                </div>
            </div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginLeft: '1rem', color: 'black' }}>
                        Loading...
                    </Typography>
                </div>
            ) : (
                <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '0.5rem' }}>
                    <ChartComponent metricsData={chartsData} options={chartOptions(chartsData)} />
                </div>
            )}
        </div>
    )
}
export default Storylines
