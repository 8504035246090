import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Card, CardContent, Typography, Tooltip, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'

const ChartComponent = ({ metricsData, options }) => {
    return (
        <div
            style={{
                width: '98vw',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '10px'
            }}
        >
            {metricsData?.map((data, index) => {
                const Chart = data.chartType === 'line' ? Line : Bar
                const chartOptions = JSON.parse(JSON.stringify(options)) // Deep clone options

                if (data.threshold !== null && data.threshold !== undefined) {
                    chartOptions.plugins = chartOptions.plugins || {}
                    chartOptions.plugins.annotation = chartOptions.plugins.annotation || {}
                    chartOptions.plugins.annotation.annotations = {
                        ...(chartOptions.plugins.annotation.annotations || {}),
                        [`thresholdLine${index}`]: {
                            type: 'line',
                            borderDash: [5, 5],
                            yMin: data.threshold,
                            yMax: data.threshold,
                            borderColor: 'rgba(255,0,0,1)',
                            borderWidth: 1,
                            label: {
                                enabled: true,
                                content: `${data.title} Threshold`,
                                position: 'end',
                                backgroundColor: 'rgba(255,99,132,0.2)',
                                color: 'rgba(255,0,0,1)'
                            }
                        }
                    }
                } else {
                    chartOptions.plugins = chartOptions.plugins || {}
                    chartOptions.plugins.annotation = chartOptions.plugins.annotation || {}
                    chartOptions.plugins.annotation.annotations = {} // No annotations for null threshold
                }

                const tooltipContent = data.metricsData.datasets
                    .map((dataset) => {
                        return `${dataset.label}: ${dataset.description || 'No description available'}`
                    })
                    .join('\n')

                return (
                    <Card
                        key={index}
                        sx={{
                            height: '95%',
                            width: '94%',
                            margin: '10px'
                        }}
                    >
                        <CardContent sx={{ textAlign: 'left' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {data.title}
                                    <Tooltip
                                        title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipContent}</div>}
                                        arrow
                                    >
                                        <IconButton size="small" sx={{ marginLeft: '4px', padding: '4px' }}>
                                            <InfoIcon fontSize="small" color="grey" />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </div>
                            <Chart sx={{ height: '100px' }} data={data.metricsData} options={chartOptions} />
                        </CardContent>
                    </Card>
                )
            })}
        </div>
    )
}

ChartComponent.propTypes = {
    metricsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    options: PropTypes.object.isRequired
}

export default ChartComponent
